import * as XLSX from "xlsx";
import "./App.css";
import { useState } from "react";
import bookCard from "./ebook.png";
function App() {
  const [data, setData] = useState(null);

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      const workbook = XLSX.read(event.target.result, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const sheetData = XLSX.utils.sheet_to_json(sheet);

      setData(sheetData);
    };

    reader.readAsBinaryString(file);
  };
  return (
    <div className="App">
      <div>
        <div className="upload">
          <input type="file" onChange={handleFileUpload} />
          <button
            onClick={() => {
              document.querySelector(".upload").style.display = "none";
              window.print()
              document.querySelector(".upload").style.display = "initial";

            }}
          >
            Print
          </button>
        </div>
        {data && (
          <div>
            {data?.map((item) => {
              return (
                <div
                  className="cardContainer"
                  style={{
                    position: "relative",
                    width: "100%",
                    // height: "200px",
                    margin: "3px",
                  }}
                >
                  <span
                    style={{
                      position: "absolute",
                      left: "45%",
                      top: "26.6%",
                      translate: "-50% -35%",
                      fontWeight: 800,
                      letterSpacing: ".8rem",
                    }}
                  >
                    {item["رقم الكارت"]}
                  </span>
                  <img
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                    }}
                    src={bookCard}
                  />
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}

export default App;
